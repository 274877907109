import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const WasKostetEineWohnungImBurgenland = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet eine Wohnung im Burgenland?" showCalc={false} />
            <Article>
                <p>
                    Du bist auf der Suche nach einer Eigentumswohnung, vielleicht sogar deiner ersten? In diesem Fall
                    ist vor allem eines wichtig: dir möglichst früh einen Überblick über das bundesländerspezifische
                    Angebot zu verschaffen und dadurch ein Gespür für regionale Unterschiede auf dem Markt zu
                    entwickeln. In den Artikeln dieser Serie widmen wir uns daher dem Immobilienmärkten der einzelnen
                    Bundesländer und geben dir einen ersten Überblick über das dortige Angebot. Diesmal dran: das
                    Burgenland.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Immobilienmarkt in Österreich: aktuelle Entwicklungen</h2>
                <p>
                    Um die jüngsten Entwicklungen auf dem Markt im Burgenland einordnen zu können, müssen wir sie im
                    Kontext der Preisentwicklung in ganz Österreich betrachten. Folgende Tendenzen waren 2021 zu
                    beobachten. Sie sind auf die seit längerem besonders günstige Fixzinsbindung bei Krediten
                    zurückzuführen:
                </p>
                <ul>
                    <li>
                        Der Anstieg der Immobilienpreise lag im zweiten Quartal nach wie vor im zweistelligen
                        Prozentbereich und betrug 11,7 %.
                    </li>
                    <li>
                        Der Preisanstieg bei Einfamilienhäusern außerhalb Wiens nahm gegenüber dem ersten Quartal (12,
                        9%) ab und betrug 11,3 %.
                    </li>
                    <li>
                        Der Preisanstieg ist außerhalb Wiens höher als innerhalb: In der Bundeshauptstadt betrug er 2021
                        im ersten Quartal 10,9 % und im zweiten 10,7 %. In den übrigen Bundesländern war ein Anstieg von
                        14,8 % bzw. 12,8 % zu beobachten.
                    </li>
                    <li>
                        Umgelegt auf den Markt an neuen Eigentumswohnungen: Hier stiegen die in Wien 2021 um 10,4 % im
                        ersten und um 10,1 % im zweiten Quartal. Außerhalb der Hauptstadt kam es dagegen hingegen zu
                        einer Teuerung von 16,7 % bzw. 12,9 %.
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Der Markt im Burgenland</h2>
                <p>
                    Ein Anstieg des Preises – sowohl von Häusern als auch von Eigentumswohnungen – ist im Burgenland
                    insbesondere an zwei Orten zu beobachten: Neusiedl am See und Eisenstadt, im zweiten Fall inklusive
                    der Umgebung. Generell hat im Burgenland längst eine preisliche Aufholjagd gegenüber anderen
                    Bundesländern begonnen. In fast allen Regionen steigen Immobilien- und Grundstückspreise aktuell.
                    Noch sind sie, im Vergleich zu den übrigen Bundesländer, aber nach wie vor recht günstig. Ein Haus-
                    oder Wohnugskauf im Burgenland lässt sich aus diesem Grund auch sehr gut als Wertanlage nutzen.
                </p>
                <hr />

                <h2>Das kosten Wohnungen im Burgenland</h2>
                <p>
                    Wir beginnen mit der Hauptstadt: Statt Eisen sehen wir einen Preis von durchschnittlich 2 133,42 €,
                    mit du beim Kauf einer Wohnung für einen Quadratmeter Wohnfläche rechnen musst. Noch ein wenig
                    teuerer fallen Wohnungen aktuell in Neusiedl am See aus, das eine rasante Preisentwicklung zeigt.
                    Pro Quadratmeter Wohnfläche bezahlst du hier im Durschnitt 2 194,75 €. Schon deutlich günstiger
                    wohnt es sich dagegen im Stadtgebiet von Rust: Aktuell liegt der Quadratmeterpreise bei Wohnungen
                    hier bei etwa 1 856,00 €. Mit nur geringer Differenz folgt Mattersburg, wo deine neue Wohnung dich 1
                    637,08 € pro Quadratmeter kostet. Noch etwas günstiger ist Güssing: Hier bezahlst du im Mittel 1 390
                    € für eine Quadratmeter.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"wasKostetEineWohnungImBurgenland"}></BreadcrumbList>
            <ArticleStructuredData
                page={"wasKostetEineWohnungImBurgenland"}
                heading={"Was kostet eine Wohnung im Burgenland?"}
            />
        </Layout>
    );
};

export default WasKostetEineWohnungImBurgenland;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.was-kostet-eine-wohnung-im-burgenland", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
